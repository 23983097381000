<template>
  <div>
    <div class="top py-10 px-8">
      <div class="top__inner mx-auto">
        <v-row class="top__row mb-0">
          <v-col align="right" class="px-0">
            <v-btn
              outlined
              width="240" height="60"
              color="grey darken-1"
              class="top__menu-btn text-body-1 font-weight-bold mr-7"
              depressed
              :to="{ name: 'SearchPage' }"
              >プロジェクトを探す</v-btn
            >
            <v-btn
              outlined
              width="240" height="60"
              color="orange darken-2"
              class="top__menu-btn text-body-1 font-weight-bold"
              depressed
              :to="{ name: 'ProjectEdit' }"
              >新規プロジェクト作成</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="top__row my-0">
          <v-col class="pl-2 pb-2 text-h5 blue--text text--darken-4"
            >最近閲覧したプロジェクト
          </v-col>
        </v-row>
        <v-row class="top__row mb-12">
          <v-col class="px-0">
            <div v-if="searchResult == 0">該当なし</div>
            <project-cards v-else :value="projectsList.projects" class="top__row mb-12"></project-cards>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-overlay :absolute="absolute" :value="overlay">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {RepositoryFactory} from '../api/RepositoryFactory'

import CommonPageHeader from "../components/atomic/organisms/CommonPageHeader.vue";
import ProjectSearchConditions from "../components/atomic/organisms/ProjectSearchConditions.vue";
import ProjectCards from "../components/atomic/organisms/ProjectCards.vue";

export default {
  components: {
    CommonPageHeader,
    ProjectSearchConditions,
    ProjectCards,
  },
  data() {
    return {
      absolute: true,
      overlay: false,
      searchResult: 0,
      projectsList: [],

    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    const cookieVal = this.$cookies.get('sdb_projectIdHist')

    if(cookieVal) {
      const param = {
        userId: this.userInfo.userId,
        // projectIds: JSON.parse(cookieVal)
        projectIds: cookieVal
      }

      const ProjectRepository = RepositoryFactory.get('project')

      ProjectRepository.getProjectList(param).then(response => {
        if (response.data.result && response.data.resultCode === '0000') {
          this.projectsList = response.data.data;
          this.searchResult = this.projectsList.projects.length;

          if(this.searchResult > 0) {
            const projectIds = JSON.parse(cookieVal)
            this.projectsList.projects.sort((a, b) => {
              const posA = projectIds.indexOf(a.id)
              const posB = projectIds.indexOf(b.id)

              return posA == posB ? 0 : (posA < posB ? -1 : 1)
            })
          }

          this.overlay = false 
        } else {
          this._sendError(response.data.message)
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.top {
  background-color: #f2f8ff;

  &__inner {
    max-width: 1160px;
  }

  &__row {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
  }

  &__menu-btn {
    background-color: #fff;
  }
}
</style>
